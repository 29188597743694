import { IRemoteNotification } from '@magicbell/magicbell-react';
import {
  NotificationCategory,
  NotificationCategoryEnum,
} from 'components/Notifications/types';
import {
  UserNotificationPreferences,
  NotificationResource,
} from '@automata/api/apiSchemas';

export const getNotificationCategory = (
  notification: IRemoteNotification
): NotificationCategory => {
  if (
    notification.category &&
    notification.category.toUpperCase() in NotificationCategoryEnum
  ) {
    return notification.category as NotificationCategory;
  }

  return 'neutral';
};

export const getCheckedStatus = (
  resources: NotificationResource | undefined,
  deviceID: string
): boolean => {
  if (!resources) return false;
  const { devices } = resources;
  if (devices && devices.length > 0) {
    return devices.includes(deviceID);
  }
  return false;
};

export const getUpdatedPreferenceCategories = (
  checked: boolean,
  categorySlug: string,
  channel: 'in_app' | 'email',
  preferences: UserNotificationPreferences
): UserNotificationPreferences => {
  const currentPreference = preferences?.categories.find(
    (category) => category.slug === categorySlug
  );
  const channelIndex = currentPreference?.channels.findIndex(
    (el) => el.slug === channel
  );

  const updatedCategories: UserNotificationPreferences['categories'] =
    preferences.categories.map((category) => {
      if (category.slug === categorySlug) {
        category.channels[channelIndex as number].enabled = checked;
      }
      return category;
    });

  return {
    ...preferences,
    categories: updatedCategories,
  };
};

export const formatCategory = (categorySlug: string): string => {
  return categorySlug.replaceAll('_', ' ');
};
