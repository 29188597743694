import { PermissionsEnum } from 'model/User';
import { GrantAccess } from 'components/GrantAccess';
import { DevicesListReal } from 'screens/DevicesScreen/DevicesList';

export default function DevicesScreenPage() {
  return (
    <GrantAccess permissionsNeeded={PermissionsEnum.RUN_RESOURCES}>
      <DevicesListReal />
    </GrantAccess>
  );
}
