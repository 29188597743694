import { IRemoteNotification } from '@magicbell/magicbell-react';
import { Uuid } from '@automata/api/apiSchemas';

export enum NotificationCategoryEnum {
  ONLINE = 'online',
  OFFLINE = 'offline',
  COMPLETED = 'completed',
  ERROR = 'error',
  ACTION_REQUIRED = 'action_required',
  NEUTRAL = 'neutral',
}

export type NotificationCategory =
  | 'online'
  | 'offline'
  | 'completed'
  | 'error'
  | 'neutral';

export type NotificationSeverity = 'success' | 'neutral' | 'warning' | 'error';

interface CustomAttributes {
  env?: string;
  name?: string;
  orgID?: Uuid;
  deviceID?: string;
  errorSev?: 'CRITICAL' | 'WARNING' | 'NORMAL';
  errorCode?: string;
}

export type AutomataNotification = Exclude<
  IRemoteNotification,
  'customAttributes'
> & {
  customAttributes: CustomAttributes;
};
