import {
  Device,
  FlatGroup,
  PaginatedFlatGroups,
} from '@automata/api/apiSchemas';
import { RoutedGroup } from './types';

/**
 * Returns the path to a group - used for building tree views
 * @param GroupItem the group to build the path for
 * @param Groups the map of all groups in the organization
 * @returns {string[]} array of strings representing the path from the root parent to the group
 */
export function getGroupPath(
  group: FlatGroup,
  groups: Map<string, FlatGroup>
): string[] {
  const path = [group.id];
  let parentId = group.parent_id;

  while (parentId && parentId !== group.id) {
    const currentParent = groups.get(parentId);
    if (currentParent) {
      path.unshift(currentParent.id);
      parentId = currentParent.parent_id;
    } else {
      break;
    }
  }

  return path;
}

/**
 * Builds a tree of groups from a list of groups
 * @param groups the list of groups to build the tree from
 * @returns a map of groups with their id as key and the path to the group added to the value
 */
export function buildGroupTree(
  groups: PaginatedFlatGroups | undefined
): Map<string, RoutedGroup> {
  if (!groups) return new Map();

  const { groups: groupsList } = groups;
  const tree = new Map<string, RoutedGroup>();
  const groupsMap = new Map(groupsList.map((group) => [group.id, group]));

  groupsList.forEach((group) => {
    const path = getGroupPath(group, groupsMap);
    tree.set(group.id, { ...group, path });
  });

  return tree;
}

/**
 * Returns the parent group id of a device
 * @param deviceId the id of the device
 * @param groups the map of all devices groups
 * @returns the id of the parent group or undefined if not found
 */
export function getParentGroupId(
  deviceId: string,
  groups: Map<string, RoutedGroup>
): string | undefined {
  const parent = [...groups.values()].find((group) => {
    return group.members.includes(deviceId);
  });

  return parent?.id;
}

/**
 * Gets the groups path to a device
 * @param device the device to get the path for
 * @param groups the map of all devices groups
 * @returns the path to the device as an array of strings parent -> root
 */
export function getDevicePath(
  device: Device,
  groups: Map<string, RoutedGroup>
): string[] {
  const path = [device.serial];
  const parentGroupId = getParentGroupId(device.id, groups);

  if (parentGroupId) {
    const parentGroup = groups.get(parentGroupId);
    path.unshift(...(parentGroup?.path || []));
  }

  return path;
}
