import { useNotificationPreferences } from 'hooks/useNotificationPreferences';
import Switch from '@mui/material/Switch';

interface NotificationListItemProps {
  userID: string;
  deviceID: string;
}

export const NotificationDeviceSwitch = ({
  userID,
  deviceID,
}: NotificationListItemProps): JSX.Element => {
  const { handleSubmitDevice, checkedHubStatus, deviceUpdateLoading } =
    useNotificationPreferences({ userID, deviceID });

  return (
    <Switch
      onClick={(e) => e.stopPropagation()}
      checked={checkedHubStatus}
      onChange={(e) => handleSubmitDevice(e, deviceID)}
      color="primary"
      disabled={deviceUpdateLoading}
      data-testid="notification-device-switch"
    />
  );
};
