import { useState } from 'react';
import ModelList from 'components/ModelList';
import { GlobalRoutes } from 'GlobalRoutes';
import { useRootUrl } from 'hooks/useRootUrl';
import { ResourceActions } from 'components/ResourceActions';
import { ResourceActionsModal } from 'components/ResourceActions/ResourceActionsModal';
import { RoutedGroup } from './types';
import { ModelNames } from 'model/types';
import { PageContainer, ListScreensHeader } from '@automata/ui';
import Box from '@mui/material/Box';
import useData from './useModelListData';

const styles = {
  dataGridContainer: {
    height: '100%',
    width: '100%',
  },
};

export const DevicesListReal = (): JSX.Element | null => {
  const { baseRouteWithOrg } = useRootUrl(GlobalRoutes.operator);
  const [selectedGroup, setSelectedGroup] = useState<RoutedGroup | undefined>(
    undefined
  );
  const [openModal, setOpenModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState<ResourceActions>(
    ResourceActions.CREATE
  );

  const data = useData({
    setSelectedAction,
    setSelectedGroup,
    setOpenModal,
  });

  return (
    <PageContainer>
      <ListScreensHeader
        pageName="Hubs"
        onConfirmAction={() => {
          setSelectedAction(ResourceActions.CREATE);
          setOpenModal(true);
        }}
      />
      <Box sx={styles.dataGridContainer}>
        <ModelList data={data} route={baseRouteWithOrg} resourceName="hubs" />
      </Box>
      <ResourceActionsModal
        resourceAction={selectedAction}
        resourceType={ModelNames.GROUP}
        resource={selectedGroup}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </PageContainer>
  );
};

DevicesListReal.displayName = 'DevicesList';
