import { useCallback, useMemo } from 'react';
import {
  useGetUserNotificationsPreferences,
  usePutUserNotificationsPreferences,
  usePostDeviceNotificationPreferences,
} from '@automata/api/apiComponents';
import {
  NotificationChannel,
  NotificationCategory,
  NotificationResource,
} from '@automata/api/apiSchemas';
import { useSnackbar } from 'hooks/useSnackbar';
import {
  getUpdatedPreferenceCategories,
  getCheckedStatus,
} from 'components/Notifications/utils';
import { getErrorMessage } from '@automata/utils';

interface UseNotifcationPreferencesProps {
  userID: string;
  deviceID?: string;
}

interface UseNotifcationPreferences {
  categories: NotificationCategory[] | undefined;
  resources: NotificationResource | undefined;
  handleSubmitCategories: (
    e: React.ChangeEvent<HTMLInputElement>,
    categorySlug: string,
    channel: 'in_app' | 'email'
  ) => void;
  handleSubmitDevice: (
    e: React.ChangeEvent<HTMLInputElement>,
    deviceID: string
  ) => void;
  categoriesUpdateLoading: boolean;
  deviceUpdateLoading: boolean;
  getChannelState: (
    channels: NotificationChannel[],
    channel: 'in_app' | 'email'
  ) => boolean | undefined;
  checkedHubStatus: boolean;
}

export const useNotificationPreferences = ({
  userID,
  deviceID,
}: UseNotifcationPreferencesProps): UseNotifcationPreferences => {
  const { data: preferences, refetch } = useGetUserNotificationsPreferences({
    pathParams: { userID },
  });
  const { mutateAsync: updatPrefRequest, isLoading: categoriesUpdateLoading } =
    usePutUserNotificationsPreferences();
  const {
    mutateAsync: updateHubNotificationPref,
    isLoading: deviceUpdateLoading,
  } = usePostDeviceNotificationPreferences();

  const { enqueueSnackbar } = useSnackbar();

  const sortedCategories = useMemo(
    () => preferences?.categories.sort((x, y) => x.slug.localeCompare(y.slug)),
    [preferences]
  );

  const checkedHubStatus = useMemo(() => {
    if (preferences?.resources && deviceID) {
      return getCheckedStatus(preferences?.resources, deviceID);
    } else {
      return false;
    }
  }, [preferences?.resources, deviceID]);

  const getChannelState = (
    channels: NotificationChannel[],
    channel: 'in_app' | 'email'
  ) => channels.find((ch) => ch.slug === channel)?.enabled;

  const handleSubmitCategories = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      categorySlug: string,
      channel: 'in_app' | 'email'
    ) => {
      if (preferences) {
        const updatedPreferences = getUpdatedPreferenceCategories(
          e.target.checked,
          categorySlug,
          channel,
          preferences
        );
        try {
          await updatPrefRequest({
            body: updatedPreferences,
            pathParams: {
              userID,
            },
          });
          enqueueSnackbar('Preferences updated', {
            variant: 'success',
            preventDuplicate: true,
          });
        } catch (err: unknown) {
          const error = getErrorMessage(err, 'unknown error');
          enqueueSnackbar(`Cannot update preferences: ${error}`, {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar('Cannot update preferences', {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [preferences, updatPrefRequest, userID, enqueueSnackbar]
  );

  const handleSubmitDevice = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, deviceID: string) => {
      if (deviceID) {
        try {
          await updateHubNotificationPref({
            body: {
              device_id: deviceID,
              enabled: e.target.checked,
            },
            pathParams: {
              userID,
              deviceID: deviceID,
            },
          });
          enqueueSnackbar('Preferences updated', {
            variant: 'success',
            preventDuplicate: true,
          });
          refetch();
        } catch (err: unknown) {
          const error = getErrorMessage(err, 'unknown error');
          enqueueSnackbar(`Cannot update preferences: ${error}`, {
            variant: 'error',
          });
        }
      }
    },
    [userID, updateHubNotificationPref, enqueueSnackbar, refetch]
  );

  return {
    categories: sortedCategories,
    resources: preferences?.resources,
    handleSubmitCategories,
    handleSubmitDevice,
    categoriesUpdateLoading,
    deviceUpdateLoading,
    getChannelState: getChannelState,
    checkedHubStatus: checkedHubStatus,
  };
};
